<template>
  <v-row>
    <!-- Start Single Counterup  -->
    <v-col md="3" sm="3" cols="6" v-for="(counter, i) in counterUpContent" :key="i">
      <div class="rn-counterup counterup_style--2 mt--30">
        <h5 class="counter count">
          <VisibilitySensor @change="onChange">
            <countTo :endVal="status ? counter.endVal : 0" :autoplay="countUp" :duration="3000"></countTo>
          </VisibilitySensor>
        </h5>
        <h4 class="description">
          {{ counter.desc }}
        </h4>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
import VisibilitySensor from "vue-visibility-sensor";
import countTo from "vue-count-to";

export default {
  components: {
    countTo,
    VisibilitySensor,
  },
  data() {
    return {
      countUp: true,
      status: false,
      counterUpContent: [
        {
          icon: "dollar-sign",
          endVal: 2880000,
          desc: `Funds Raised`,
        },
        {
          icon: "dollar-sign",
          endVal: 2140000,
          desc: `Managed Marketing Budgets`,
        },
        {
          icon: "check-circle",
          endVal: 14,
          desc: `Endeavours Tackled`,
        },
        {
          icon: "award",
          endVal: 2,
          desc: `Exits`,
        },
      ],
    };
  },
  methods: {
    onChange(isVisible) {
      if (isVisible) {
        this.status = true;
      }
    },
  },
};
</script>