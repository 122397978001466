<template>
  <v-row class="service-main-wrapper mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="12"
      cols="6"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="service service__style--2 bg-gray">
        <router-link to="/service-details">
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
        {
          icon: "layers",
          title: "1. Research",
          desc: `Delving into your business landscape, we unearth pivotal insights and challenges. This foundational exploration paves the way for informed, data-driven hypotheses, setting the stage for transformative strategies.`,
        },
        {
          icon: "pie-chart",
          title: "2. Measure",
          desc: `“You can’t improve what you don’t measure” - Peter Drucker. By pinpointing exact KPIs and baselines, we ensure every step forward is quantifiable, turning success from an abstract goal into a tangible metric.`,
        },
        {
          icon: "power",
          title: "3. Activate",
          desc: `Armed with insights and metrics, we spring into action. Every move is calculated, deliberate, and tailored to meet and exceed the objectives we've set.`,
        },
        {
          icon: "repeat",
          title: "4. Evolve",
          desc: `As the digital world shifts, so do we. Continuously analysing real-time data, we adapt and refine our strategies, ensuring you're always at the forefront of digital evolution.`,
        },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
