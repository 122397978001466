<template>
  <v-row class=" service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="service service__style--2 text-left bg-gray">
        <router-link to="/service-details">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Business Analysis & Hypothesis Generation",
            desc: `We meticulously analyse your business landscape, identifying opportunities and formulating data-driven hypotheses to guide strategy and execution.`,
          },
          {
            icon: "layers",
            title: "KPIs, Baselines & Reporting",
            desc: `By setting clear KPIs and baselines, we emphasise the adage: 'You can't improve what you don't measure.'`,
          },
          {
            icon: "users",
            title: "Strategy Development & Execution",
            desc: `We craft a strategy tailored to your distinct needs, and using a data-driven approach, we execute and iteratively enhance based on insightful analytics.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
