<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <!--<div class="icon" v-html="iconSvg(icon)"></div>-->
            <h2 class="heading-title">Alexis' Journey</h2>
            <p class="description">
              Fuelled by an unwavering passion for startups, Alexis embarked on
              his entrepreneurial journey from the tender age of 7, navigating
              various ventures along the way. Each presented its own set of
              challenges, sacrifices, and invaluable lessons, some brought
              immense fulfilment and a couple saw successful exits.
            </p>
            <p class="description">
              Now, Alexis selectively collaborates with a handful of ventures,
              leveraging his decades of experience to help them address and
              overcome the myriad of challenges he's all too familiar with.
            </p>
            <p>
              His passion is marketing and tech but he has been through it all
              from building out marketing campaigns to multimillion dollar fund
              raises and exits. There is no substitute for experience.
            </p>
            <div class="purchase-btn">
              <router-link class="btn-transparent" to="/"
                >Chat to Me</router-link
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      icon: "send",
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
