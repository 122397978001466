<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
      class="mt--30"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <!-- <a href="portfolio-details.html"> -->
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <router-link to="/portfolio-details">{{
                  item.category
                }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link to="/portfolio-details">{{
                  item.title
                }}</router-link>
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <router-link
          class="transparent_link"
          to="/portfolio-details"
        ></router-link>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
          src: require("../../assets/images/work/Handy-Cats.jpg"),
          category: "Marketing & Development",
          title: "Handy Cats",
          slug: "handycats",
          description:
            "Co-owner, a tech and marketing firm that has successfully crafted and launched a range of products and ventures. We still cater to a curated group of businesses, addressing their development and marketing needs.",
        },
        {
          src: require("../../assets/images/work/solstreet presentation.jpg"),
          category: "Blockchain",
          title: "Solstreet Finance",
          slug: "solstreet",
          description:
            "A founding member of Solstreet, a decentralised asset management protocol on the Solana blockchain. Secured $2 million in seed funding & grew a vibrate twitter community 100k at it's peak and 15k discord.",
        },
        {
          src: require("../../assets/images/work/lilysonline.jpg"),
          category: "eCommerce",
          title: "LilysOnline",
          slug: "lilysonline",
          description:
            "Co-founded LilysOnline, an eCommerce site offering pet products and its signature range of healthy dog treats. Achieved a successful exit.",
        },
        {
          src: require("../../assets/images/work/safari.jpg"),
          category: "Travel & Tourism",
          title: "Safari.com",
          slug: "safari",
          description:
            "Served as Head of Product & CEO for Safari.com, an online safari travel agency boasting a robust sales team. Successfully sold 50% to a listed company.",
        },
          {
            src: require("../../assets/images/work/nomanini.jpg"),
            category: "Fintech",
            title: "nomanini",
            slug: "nomanini",
            description:
              "Served as Head of Production, Sales & Marketing at Nomanini, a fintech platform that connects financial service providers and fast moving consumer goods companies to retail MSMEs",
          },
        ],
      };
    },
  };
</script>
