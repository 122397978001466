<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePage>
      <img slot="logo" :src="logoLight" class="logo-light" />
      <img slot="logo-dark" :src="logoDark" class="logo-dark" />
    </HeaderOnePage>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="im_modern_slider poss_relative" id="home">
      <vue-particles
        color="#fd4766"
        :particleOpacity="0.7"
        :particlesNumber="40"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="180"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="repulse"
        :clickEffect="false"
        clickMode="push"
      >
      </vue-particles>
      <div
        class="slide slide-style-2 bg_image bg_image--27 d-flex align-center"
      >
        <Banner>
          <h1 slot="heading-title" class="heading-title">
            Innovation Meets Imagination
          </h1>
          <p slot="description" class="description_style-2">
            With years of experience, I've discovered that a collaborative
            approach to crafting holistic strategies is the most effective way
            to meet your business goals. How can I help you?
          </p>
          <router-link slot="button" class="btn-default btn-large" to="/contact"
            >Book a Call</router-link
          >
        </Banner>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area  -->
    <div
      class="service-area creative-service-wrapper ptb--120 bg_color--1"
      id="service"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-left section-title mb--30">
              <span class="subtitle">My Approach to Every New Partnership</span>
              <h2 class="heading-title">Online Sales and Marketing</h2>
              <p class="description">
                Imagine your business as a bustling marketplace. Online
                marketing isn’t just the product on the stall; it’s the
                intricate pathways, crowd flows, vendor interactions, stalls and
                banners and so much more. Merely screaming louder won’t address
                the hidden challenges of congestion points, positioning,
                merchandising and so many other things that result in a
                successful sale.
              </p>
              <p class="description">
                At times, the solution is clear-cut: prioritise new leads, a hot
                lead is hot and a cold lead will remain that way. I personally
                implemented this with my sales team of 52 and as a result saw
                conversion rates and sales skyrocket.
              </p>
              <p class="description">
                Other times the solution is hidden deep in the weeds of a poorly
                performing server, subpar checkout UX and badly configured
                marketing campaign.
              </p>
              <p class="description">
                I help businesses build these solutions and thrive.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceFour />
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start About Area  -->
    <div class="about-area ptb--120 bg_color--5" id="about">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../../assets/images/about/about-me-video.jpg"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Portfolio Area -->
    <div class="portfolio-area pt--120 pb--140 bg_color--1" id="portfolio">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-left section-title mb--30 mb_sm--0">
              <span class="subtitle">Some History</span>
              <h2 class="heading-title">A Few Stories</h2>
              <p class="description">
                They say there is no substitute for experience. Over almost two
                decades I have experienced many failures, learnt countless
                lessons and succeeded in a few endeavours. These are a few of
                those stories.
              </p>
            </div>
            <CounterTwo />
          </v-col>
        </v-row>
        <PortfolioNine />
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area bg_color--5 ptb--120" id="tesimonial">
      <v-container>
        <Testimonial />
      </v-container>
    </div>
    <!-- Start Testimonial Area  -->

    <!-- Start Blog Area  -->
    <div class="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="12">
            <div class="text-left section-title">
              <span class="subtitle">what's stories on</span>
              <h2 class="heading-title">Latest News</h2>
              <p class="description">
                There are many variations of passages of Lorem Ipsum available,
                <br />
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row>
        <BlogTwo />
      </v-container>
    </div>
    <!-- End Blog Area  -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-us ptb--120 bg_color--5" id="contact">
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-12.jpg"
              alt="contact images"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <!-- Start Brand Area -->
    <div class="rn-brand-area brand-inside-gutter-free bg_color--1 ptb--120">
      <v-container>
        <v-row>
          <v-col cols="12">
            <Brand />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Brand Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderOnePage from "../../components/header/HeaderOnePage";
import Banner from "../../components/slider/Banner";
import About from "../../components/about/About";
import ServiceThree from "../../components/service/ServiceThree";
import PortfolioNine from "../../components/portfolio/PortfolioNine";
import CounterOne from "../../components/counter/CounterOne";
import Team from "../../components/team/Team";
import Testimonial from "../../components/testimonial/Testimonial";
import BlogTwo from "../../components/blog/BlogTwo";
import Contact from "../../components/contact/Contact";
import Brand from "../../components/brand/Brand";
import Footer from "../../components/footer/FooterTwo";
import ServiceFour from "../../components/service/ServiceFour.vue";
import CounterTwo from "../../components/counter/CounterTwo.vue";

export default {
  components: {
    HeaderOnePage,
    Banner,
    About,
    ServiceThree,
    PortfolioNine,
    CounterOne,
    Team,
    Testimonial,
    BlogTwo,
    Brand,
    Contact,
    Footer,
    ServiceFour,
    CounterTwo,
  },
  data() {
    return {
      logoLight: require("../../assets/images/logo/logo-light.png"),
      logoDark: require("../../assets/images/logo/logo-dark.png"),
    };
  },
};
</script>
